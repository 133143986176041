import React, {createContext, useEffect, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {delay, errorHandler, niceUsername, useLocationQuery} from "../utilities/funcs";
import {PROFILES_WITH_USERS_AND_USERS_WITH_PROFILES_QUERY, USER_FROM_TOKEN_QUERY} from "../utilities/query";
import {USER_LOGIN_LIKE_MUTATION, USER_LOGIN_MUTATION, USER_LOGOUT_MUTATION} from "../utilities/mutation";

const pollInterval = 300000
const delayTime = 0

export const AuthProvider = props => {

    const [loading, setLoading] = useState(true)
    const urlQuery = useLocationQuery()
    const [user, setUser] = useState({})
    // const [messages, setMessages] = useState([])
    const [users, setUsers] = useState([])
    const [constants, setConstants] = useState([])
    const [profiles, setProfiles] = useState([])
    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate();


    const [userFromTokenQuery, {startPolling, stopPolling, client}] = useLazyQuery(USER_FROM_TOKEN_QUERY, {
        fetchPolicy: "network-only",
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
            // console.log('onError - userFromTokenQuery')
            clearData()
            delay(delayTime).then(setLoading(false))
            stopPolling()
        },
        onCompleted: (d) => {
            // console.log('onCompleted - userFromTokenQuery')
            // console.log(d)

            if (d.me) {
                // console.log('setting user')
                setUser(d.me)
                loadProfilesAndUsers()
            } else {
                clearData()
                stopPolling()
                //enqueueSnackbar("Sessie beëindigd. U bent uitgelogd.")
                //navigate("/")
            }
            delay(delayTime).then(setLoading(false))
        },
        variables: {},
        pollInterval: pollInterval
    });

    const [userLogoutMutation] = useMutation(USER_LOGOUT_MUTATION, {
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
            stopPolling()
            setLoading(false)
        },
        onCompleted: (d) => {
            clearData()
            stopPolling()
            delay(delayTime).then(setLoading(false))
        },
    });


    const [userLoginMutation] = useMutation(USER_LOGIN_MUTATION, {
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
            stopPolling()
            setLoading(false)
        },
        onCompleted: (d) => {
            enqueueSnackbar("Succesvol ingelogd", {variant: "success"})
            setUser(d.userLogin.user)
            startPolling(pollInterval)
            delay(delayTime).then(setLoading(false))
            loadProfilesAndUsers()
            if (urlQuery.get('redirect')) {
                navigate(urlQuery.get('redirect'))
            } else {
                navigate('/successlogin')
            }
        },
    });

    const [userLoginLike] = useMutation(USER_LOGIN_LIKE_MUTATION, {
        onCompleted: (d) => {
            enqueueSnackbar('U bent ingelogd als ' + niceUsername(d.userLoginLike.user), {variant: "success"})
            setUser(d.userLoginLike.user)
            startPolling(pollInterval)
            delay(delayTime).then(setLoading(false))
            loadProfilesAndUsers()
            navigate("/")
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
    })

    const [loadProfilesAndUsers] = useLazyQuery(PROFILES_WITH_USERS_AND_USERS_WITH_PROFILES_QUERY, {
        notifyOnNetworkStatusChange: true,
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
        onCompleted: data => {
            setProfiles(data.profiles)
            setUsers(data.users)
            // setConstants(data.constants)
        }
    });

    useEffect(() => {
        // vervangt onMountComponent
        // console.log('useEffect AuthProvider')
        setLoading(true)
        updateContextFromToken()
    }, []);


    const clearData = () => {
        setUser({})
        setUsers([])
        setConstants([])
        setProfiles([])
        client.resetStore()
    }

    const login = (data) => {
        setLoading(true)
        userLoginMutation(data)
    }

    const logout = async () => {
        setLoading(true)
        await userLogoutMutation()
    };

    const userLoggedIn = () => {
        // console.log("testing user")
        return Boolean(user?.id)
    }
    const isUser = (testUserId) => {
        return testUserId === user?.id
    }
    const isInUsers = (testUserIds) => {
        for (let testUserId of testUserIds) {
            if (isUser(testUserId)) {
                return true
            }
        }
        return false
    }

    const userInProfile = (profile_ids) => {
        if (user.hasOwnProperty('profiles')) {
            const testIds = getProfileIdsActionname(profile_ids)
            for (let testProfile of testIds) {
                for (let profile of user.profiles) {
                    if (profile.id === testProfile) return true
                }
            }
        }
        return false
    }

    const getProfileIdsActionname = ids => {
        if (typeof ids === 'string') {

            return []
        } else {
            return ids
        }


    }

    const getConstant = title => {
        for (let constant of constants) {
            if (constant.title === title) {
                return constant.value
            }
        }
        return null
    }


    const updateContextFromToken = async () => {
        setLoading(true)
        // console.log('updateContextFromToken')
        await userFromTokenQuery()
    }

    const consoleUser = () => {
        console.log(user)
        return 'test'
    }

    return (
        <AuthContext.Provider value={{
            user: user,
            //messages: messages,
            loading: loading,
            login: login,
            logout: logout,
            userInProfile: userInProfile,
            isUser: isUser,
            isInUsers: isInUsers,
            userLoggedIn: userLoggedIn,
            userLoginLike: userLoginLike,
            users: users,
            constants: constants,
            profiles: profiles,
            getConstant: getConstant,
            consoleUser:consoleUser
        }}>
            {props.children}
        </AuthContext.Provider>
    )

}


export const AuthContext = createContext({
    user: {},
    //messages: messages,
    loading: false,
    login: () => {
    },
    logout: () => {
    },
    userLoggedIn: () => {
    },
    userLoginLike: () => {
    },
    // user: {},
    // messages: [],
    // notifications: 0,
    // menuitems: [],
    // loading: true,
    // login: () => {
    // },
    // logout: () => {
    // },
    userInProfile: (profileIds) => {
    },
    isUser: (testUserId) => {
    },
    isInUsers: (testUserIds) => {
    },
    users: [],
    constants: [],
    profiles: [],
    getConstant: (title) => {
    },
    consoleUser: ()=>{}
});