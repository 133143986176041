import React, {useContext} from 'react'

import {Card, CardContent, CardHeader, Container, Divider, Typography} from "@mui/material";
import {niceUsername} from "../utilities/funcs";
import {AuthContext} from "../context/AuthProvider";
import FormCreate from "../form/FormCreate/FormCreate";
import FormCreateText from "../form/FormCreate/FormCreateText";
import {object, string} from "yup";


const UserLogin = (props) => {
    const userContext = useContext(AuthContext);

    return (
        <Container maxWidth={"md"}>
            {userContext.userLoggedIn() &&
                <Typography>U bent reeds ingelogd als {niceUsername(userContext.user)}.</Typography>}
            {!userContext.userLoggedIn() &&
                <Card>
                    <CardHeader title="Login"/>
                    <Divider/>
                    <CardContent>
                        <FormCreate
                            initialValues={{
                                username: "",
                                password: ""
                            }}
                            submitFunc={(data) => {
                                userContext.login({
                                    variables: {
                                        username: data.username,
                                        password: data.password,
                                    }
                                })
                            }}
                            sendText={"inloggen"}
                            loading={userContext.loading}
                            validationSchema={object().shape({
                                username: string()
                                    .email()
                                    .max(250, 'Maximaal 250 tekens')
                                    .required('Dit veld is vereist'),
                                password: string()
                                    .max(250, 'Maximaal 250 tekens')
                                    .required('Dit veld is vereist'),
                            })}
                        >
                            <FormCreateText
                                name={"username"}
                                label={"Gebruikersnaam"}
                            />
                            <FormCreateText
                                name={"password"}
                                label={"Wachtwoord"}
                                type={"password"}

                            />
                        </FormCreate>
                    </CardContent>
                </Card>}
        </Container>
    )
}


export default UserLogin