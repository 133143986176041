import {gql} from "@apollo/client";
import {
    APPOINTMENT_FRAGMENT,
    FILE_FRAGMENT,
    INVOICE_FRAGMENT,
    NEWSITEM_FRAGMENT,
    NEWSLETTER_FRAGMENT,
    NEWSLETTERITEM_FRAGMENT,
    ORDER_FRAGMENT,
    ORDERITEM_FRAGMENT,
    PAGE_FRAGMENT,
    PRODUCT_FRAGMENT,
    SUBPRODUCT_FRAGMENT,
    USER_FRAGMENT
} from "./fragments";


export const USER_FROM_TOKEN_QUERY = gql`
${USER_FRAGMENT}
    query UserFromToken{
        me{
            ...UserFragment
        }
    }
`;


export const APPCONTEXT_QUERY = gql`
    query Appcontext{
        profiles{
            id
            title
            permissions{
                id
                title
            }
        }
        permissions{
            id
            title
        }
    }
`;

export const PROFILES_WITH_USERS_AND_USERS_WITH_PROFILES_QUERY = gql`
    ${USER_FRAGMENT}
    query ProfilesWithUsersAndUsersWithProfiles{
        profiles{
            id
            title
            users(where: {active: {equals: true}}){
                id
                prefix
                prename
                insertion
                surname
            }
        }
        users(where: {active: {equals: true}}){
            ...UserFragment
        }
        
    }
`;
export const PROFILES_QUERY = gql`
    query Profiles($where: ProfileWhereInput, $orderBy: [ProfileOrderByWithRelationInput!], $take: Int, $skip: Int, $whereAggregate: ProfileWhereInput){
        profiles(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
        }
        aggregateProfile(where: $whereAggregate){
            _count{
                id
            }
        }
    }
`;

export const PROFILE_QUERY = gql`
    query Profile($where: ProfileWhereUniqueInput!){
        profile(where: $where){
            id
            title
            permissions {
                id
                title
            }
        }
    }
`;

export const PAGE_QUERY = gql`
    ${PAGE_FRAGMENT}
    query Page($where: PageWhereUniqueInput!){
        page(where: $where){
            ...PageFragment
        }
    }
`;

export const PAGES_QUERY = gql`
    query Pages($where: PageWhereInput, $orderBy: [PageOrderByWithRelationInput!], $take: Int, $skip: Int){
        pages(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            name
        }
        aggregatePage{
            _count{
                id
            }
        }
    }
`;

export const APPOINTMENTS_QUERY = gql`
    query Appointments($where: AppointmentWhereInput, $orderBy: [AppointmentOrderByWithRelationInput!], $take: Int, $skip: Int){
        appointments(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            start
            end
            showFrom
            showTo
            location
            modified
            product{
                id
            }
            files{
                id
                mimetype
                model
                modelId
                filename
            }
            musicworks{
                id
                title
                composer
            }
            executives{
                id
                title
                function
            }
            choirs{
                id
                title
            }
        }
        aggregateAppointment{
            _count{
                id
            }
        }
    }
`;

export const APPOINTMENTS_ADMIN_QUERY = gql`
    query Appointments($where: AppointmentWhereInput, $orderBy: [AppointmentOrderByWithRelationInput!], $take: Int, $skip: Int){
        appointments(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            start
            location
            gcal
            access
            product{
                id
            }
            choirs{
                id
                short
            }
        }
        aggregateAppointment{
            _count{
                id
            }
        }
    }
`;

export const APPOINTMENT_QUERY = gql`
    ${APPOINTMENT_FRAGMENT}
    query Appointment($where: AppointmentWhereUniqueInput!){
        appointment(where: $where){
            ...AppointmentFragment
        }
        profiles{
            id
            title
        }
    }
`;

export const MUSICWORKS_QUERY = gql`
    query Musicworks($where: MusicworkWhereInput, $orderBy: [MusicworkOrderByWithRelationInput!], $take: Int, $skip: Int, $whereAggregate: MusicworkWhereInput){
        musicworks(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            composer
        }
        aggregateMusicwork(where: $whereAggregate){
            _count{
                id
            }
        }
    }
`;

export const MUSICWORK_QUERY = gql`
    query Musicwork($where: MusicworkWhereUniqueInput!){
        musicwork(where: $where){
            id
            title
            composer
        }
    }
`;


export const EXECUTIVES_QUERY = gql`
    query Executives($where: ExecutiveWhereInput, $orderBy: [ExecutiveOrderByWithRelationInput!], $take: Int, $skip: Int, $whereAggregate: ExecutiveWhereInput){
        executives(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            function
        }
        aggregateExecutive(where: $whereAggregate){
            _count{
                id
            }
        }
    }
`;


export const EXECUTIVE_QUERY = gql`
    query Executive($where: ExecutiveWhereUniqueInput!){
        executive(where: $where){
            id
            title
            function
        }
    }
`;

// export const INVOICES_QUERY = gql`
//     query Invoices($where: InvoiceWhereInput, $orderBy: [InvoiceOrderByWithRelationInput!], $take: Int, $skip: Int){
//         invoices(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
//             id
//             created
//         }
//         aggregateInvoice{
//             _count{
//                 id
//             }
//         }
//     }
// `;
export const INVOICE_QUERY = gql`
    ${INVOICE_FRAGMENT}
    query Invoice($where: InvoiceWhereUniqueInput!){
        invoice(where: $where){
            ...InvoiceFragment
            creditInvoices {
                id
            }
        }
    }
`;


export const ORDERS_QUERY = gql`
    query Orders($where: OrderWhereInput, $orderBy: [OrderOrderByWithRelationInput!], $take: Int, $skip: Int, $whereAggregate: OrderWhereInput ){
        orders(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            salutation
            prename
            insertion
            surname
            created
            archive
            active
        }
        aggregateOrder(where: $whereAggregate){
            _count{
                id
            }
        }
    }
`;

export const ORDER_W_ORDERITEMS_QUERY = gql`
    ${ORDER_FRAGMENT}
    ${ORDERITEM_FRAGMENT}
    query Order($where: OrderWhereUniqueInput!){
        order(where: $where){
            ...OrderFragment
            orderitems{
                ...OrderitemFragment
            }
            invoices{
                id
                payed
                price
                creditToInvoice{
                    id
                }
            }
        }
    }
`;

export const ORDER_QUERY = gql`
    ${ORDER_FRAGMENT}
    query Order($where: OrderWhereUniqueInput!){
        order(where: $where){
            ...OrderFragment
        }
    }
`;

export const ORDERITEMS_QUERY = gql`
    query Orderitems($where: OrderitemWhereInput, $orderBy: [OrderitemOrderByWithRelationInput!], $take: Int, $skip: Int, $whereAggregate: OrderitemWhereInput){
        orderitems(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            type
            title
            amount
            payed
            send
        }
        aggregateOrderitem(where: $whereAggregate){
            _count{
                id
            }
        }
    }
`;

export const ORDERITEM_QUERY = gql`
    ${ORDERITEM_FRAGMENT}
    query Orderitem($where: OrderitemWhereUniqueInput!){
        orderitem(where: $where){
            ...OrderitemFragment
        }
    }
`;


export const PRODUCTS_AND_SUBPRODUCTS_QUERY = gql`
    query ProductsAndSubproducts($where: ProductWhereInput, $orderBy: [ProductOrderByWithRelationInput!], $take: Int, $skip: Int){
        products(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            description{
                id
                nl
                en
            }
            type
            active
            subproducts{
                id
                title
                price
                tax
            }
            files{
                id
                mimetype
                model
                modelId
                path
                filename
            }
        }
    }
`;

export const PRODUCT_SUBPRODUCTS_ORDERITEMS__QUERY = gql`
    query ProductSubproductsOrderitems($where: ProductWhereUniqueInput!){
        product(where: $where){
            id
            title
            type
            active
            subproducts{
                id
                title
                price
                tax
                sold
                stock
                orderitems{
                    id
                    title
                    payed
                    amount
                    order{
                        id
                        salutation
                        prename
                        insertion
                        surname
                        email
                    }
                }
            }
        }
    }
`;


export const PRODUCTS_QUERY = gql`
    query Products($where: ProductWhereInput, $orderBy: [ProductOrderByWithRelationInput!], $take: Int, $skip: Int){
        products(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            type
        }
        aggregateProduct{
            _count{
                id
            }
        }
    }
`;

export const PRODUCT_QUERY = gql`
    ${PRODUCT_FRAGMENT}
    query Product($where: ProductWhereUniqueInput!){
        product(where: $where){
            ...ProductFragment
        }
    }
`;


export const SUBPRODUCTS_QUERY = gql`
    query Subproducts($where: SubproductWhereInput, $orderBy: [SubproductOrderByWithRelationInput!], $take: Int, $skip: Int){
        subproducts(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            price
            stock
            sold
        }
        aggregateSubproduct{
            _count{
                id
            }
        }
    }
`;

export const SUBPRODUCT_QUERY = gql`
    ${SUBPRODUCT_FRAGMENT}
    query Subproduct($where: SubproductWhereUniqueInput!){
        subproduct(where: $where){
            ...SubproductFragment
        }
        profiles{
            id
            title
        }
    }
`;

export const NEWSLETTERS_QUERY = gql`
    query Newsletters($where: NewsletterWhereInput, $orderBy: [NewsletterOrderByWithRelationInput!], $take: Int, $skip: Int){
        newsletters(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            created
            send
        }
        aggregateNewsletter{
            _count{
                id
            }
        }
    }
`;

export const NEWSLETTER_QUERY = gql`
    ${NEWSLETTER_FRAGMENT}
    query Newsletter($where: NewsletterWhereUniqueInput!){
        newsletter(where: $where){
            ...NewsletterFragment
        }
    }
`;

export const NEWSLETTER_BANNERS_QUERY = gql`
    ${FILE_FRAGMENT}
    ${NEWSLETTER_FRAGMENT}
    query NewsletterBanners($where: NewsletterWhereUniqueInput!){
        newsletter(where: $where){
            ...NewsletterFragment
        }
        banners{
            id
            files{
                ...FileFragment
            }
        }
    }
`;

export const NEWSLETTERITEMS_QUERY = gql`
    ${NEWSLETTERITEM_FRAGMENT}
    query Newsletteritems($where: NewsletteritemWhereInput, $orderBy: [NewsletteritemOrderByWithRelationInput!], $take: Int, $skip: Int){
        newsletteritems(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            ...NewsletteritemFragment
        }
        aggregateNewsletteritem{
            _count{
                id
            }
        }
    }
`;

export const NEWSLETTERITEM_QUERY = gql`
    ${NEWSLETTERITEM_FRAGMENT}
    query Newsletteritem($where: NewsletteritemWhereUniqueInput!){
        newsletteritem(where: $where){
            ...NewsletteritemFragment
        }
    }
`;

export const RECEIVERS_QUERY = gql`
    query Receivers($where: ReceiverWhereInput, $orderBy: [ReceiverOrderByWithRelationInput!], $take: Int, $skip: Int){
        receivers(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            email
        }
        aggregateReceiver{
            _count{
                id
            }
        }
    }
`;

export const RECEIVER_QUERY = gql`
    query Receiver($where: ReceiverWhereUniqueInput!){
        receiver(where: $where){
            id
            email
        }
    }
`;


export const USERS_QUERY = gql`
    query Users($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!], $take: Int, $skip: Int){
        users(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            prename
            insertion
            surname
            email
            active
        }
        aggregateUser{
            _count{
                id
            }
        }
    }
`;

export const USER_QUERY = gql`
    ${USER_FRAGMENT}
    query User($where: UserWhereUniqueInput!){
        user(where: $where){
            ...UserFragment
        }
    }
`;

export const CARTITEMS_QUERY = gql`
    query Cartitems{
        cartitems{
            id
            amount
            subproduct{
                id
                title
                price
                tax
                product{
                    id
                    title
                    type
                }
            }
        }
    }
`;


export const NEWSITEMS_QUERY = gql`
    ${NEWSITEM_FRAGMENT}
    query Newsitems($where: NewsitemWhereInput, $orderBy: [NewsitemOrderByWithRelationInput!], $take: Int, $skip: Int){
        newsitems(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            ...NewsitemFragment
        }
        aggregateNewsitem{
            _count{
                id
            }
        }
    }
`;

export const NEWSITEM_QUERY = gql`
    ${NEWSITEM_FRAGMENT}
    query Newsitem($where: NewsitemWhereUniqueInput!){
        newsitem(where: $where){
            ...NewsitemFragment
        }
    }
`;


export const EMAILPRESETS_QUERY = gql`
    query EmailPresets($where: EmailPresetWhereInput, $orderBy: [EmailPresetOrderByWithRelationInput!], $take: Int, $skip: Int){
        emailPresets(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
        }
        aggregateEmailPreset{
            _count{
                id
            }
        }
    }
`;

export const EMAILPRESETS_ALL_QUERY = gql`
    query EmailPresets($where: EmailPresetWhereInput, $orderBy: [EmailPresetOrderByWithRelationInput!], $take: Int, $skip: Int){
        emailPresets(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            subject
            from
            text{
                id
                nl
                en
            }
        }
    }
`;

export const EMAILPRESET_QUERY = gql`
    query EmailPreset($where: EmailPresetWhereUniqueInput!){
        emailPreset(where: $where){
            id
            title
            from
            subject
            text{
                id
                nl
                en
            }
        }
    }
`;

export const BANNERS_QUERY = gql`
    ${FILE_FRAGMENT}
    query Banners($where: BannerWhereInput, $orderBy: [BannerOrderByWithRelationInput!], $take: Int, $skip: Int){
        banners(where: $where, orderBy: $orderBy, skip: $skip, take: $take){
            id
            title
            files{
                ...FileFragment
            }
        }
        aggregateBanner{
            _count{
                id
            }
        }
    }
`;

export const BANNER_QUERY = gql`
    ${FILE_FRAGMENT}
    query Banner($where: BannerWhereUniqueInput!){
        banner(where: $where){
            id
            files{
                ...FileFragment
            }
        }
    }
`;