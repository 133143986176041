import {Box, IconButton, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";
import React, {useEffect} from "react";
import {ToggleOff, ToggleOn} from "@mui/icons-material";

const FormCreateText = (
    {
        boxSx = {},
        onValuesChange = () => {
        },
        onValuesChangeDependant = () => {
        },
        nullable=false,
        name,
        label,
        type="text"

    }
) => {

    const {
        values,
        touched,
        errors, status,
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(name);


    useEffect(() => {
        onValuesChange(values, setFieldValue)
    }, onValuesChangeDependant(values))


    return (
        <Box sx={{paddingBottom: "8px", paddingTop: '16px', display: "flex", ...boxSx}}>
            <TextField
                value={field.value !== null ? field.value : ''}
                disabled={Boolean(status?.readOnly) || field.value === null}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={label}
                error={touched[name] && Boolean(errors[name])}
                helperText={errors[name]}
                fullWidth
                InputLabelProps={{shrink: true}}
                type={type}
            />
            {nullable && <Box>
                <IconButton
                    sx={{p: 2, mr: 0.2, border: "1px solid #c4c4c4"}}
                    onClick={() => {
                        if (field.value !== null) {
                            field.onChange({target: {name: field.name, value: null}})
                        } else {
                            field.onChange({
                                target: {
                                    name: field.name,
                                    value: ""
                                }
                            })
                            //todo open dialog
                        }
                    }}
                    edge="end"
                    color={field.value === null ? 'default' : 'primary'}
                >
                    {field.value === null ? <ToggleOff/> : <ToggleOn/>}
                </IconButton>
            </Box>}
        </Box>
    )
}
export default FormCreateText
