import {DateTime} from "luxon";
import {BASE_URL_CLIENT, BASE_URL_SERVER} from "./constants";
import {Album, ConfirmationNumber, LocalShipping, VolunteerActivism} from "@mui/icons-material";
import {Typography} from "@mui/material";
import {useLocation} from "react-router-dom";
import React from "react";

export const errorHandler = (apolloError, snackbar) => {
    if (apolloError.message) {
        snackbar(apolloError.message, {variant: 'error'})
    } else {
        snackbar('andere error', {variant: 'error'})
    }
}

export const valueGetterDate = (value) => {
    return niceDateTime(value, 'date', '')
}

export const valueGetterDatetime = (value) => {
    return niceDateTime(value, 'datetime', '')
}


export const niceDateTime = (value, format = "datetime", msg = "") => {
    if (value !== null && value !== undefined) {
        let v = value
        if (typeof value === "string") {
            v = DateTime.fromISO(value)
        }
        if (format === "datetime") return v.toLocaleString(DateTime.DATETIME_MED)
        if (format === "date") return v.toLocaleString(DateTime.DATE_MED)
        if (format === "time") return v.toLocaleString(DateTime.TIME_24_SIMPLE)
        return 'ongeldig format'
    }
    return msg
}


export const formatSortBy = o => {
    if (!o || o.length === 0) return undefined
    return o.map(item => {
        return {[item.field]: item.sort}
    })
}

export const capitalize = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const niceUsername = ({salutation, prename, insertion, surname}, long = true) => {
    if (!long) return insertion ? `${insertion} ${surname}` : surname
    if (long) {
        return ((salutation && salutation !== 'nvt') ? salutation + ' ' : '') + prename + " " + (insertion ? insertion + ' ' : '') + surname
    }
}


export const createWhereClause = (searchTexts, searchFields, filter) => {
    if (Array.isArray(searchTexts) && searchTexts.length > 0 && searchFields.length > 0) {
        const items = []
        searchFields.forEach(searchField => {
            searchTexts.forEach(searchText => {
                items.push({[searchField]: {contains: searchText}})
            })
        })
        if (filter !== {}) {
            return {AND: [filter, (items.length > 1) ? {OR: items} : items[0]]}
        }
        return (items.length > 1) ? {OR: items} : items[0]
    }
    return filter

}

export const getFirstImageOrNoPhotoFile = (files = [], choirs = []) => {
    for (const file of files) {
        if (['image/jpeg', 'image/png'].includes(file.mimetype)) return `${BASE_URL_SERVER}/file/${file.model}/${file.modelId}/${file.filename}`
    }
    return `${BASE_URL_CLIENT}/images/no_image.png`
}


export const getFirstImageOrLogo = (files = [], choirs = []) => {
    for (const file of files) {
        if (['image/jpeg', 'image/png'].includes(file.mimetype)) return `${BASE_URL_SERVER}/file/${file.model}/${file.modelId}/${file.filename}`
    }
    return `${BASE_URL_CLIENT}/logo/large/LOGO_LV_NO_TEXT.png`
}

export const getImageOrIcon = (file) => {
    if (['image/jpeg', 'image/png'].includes(file.mimetype)) return `${BASE_URL_SERVER}/file/${file.model}/${file.modelId}/${file.filename}`
    //todo return based on mimetype or extension
    return `${BASE_URL_CLIENT}/logo/large/LOGO_LV_NO_TEXT.png`
}

export const getShoppingCartIcon = (type, options = {}) => {
    switch (type) {
        case 'ticket':
            return <ConfirmationNumber {...options} />
        case 'cd':
            return <Album {...options}/>
        case 'donation':
            return <VolunteerActivism {...options}/>
        case 'shipping':
            return <LocalShipping {...options}/>
        default:
            return null
    }
}

export const niceOrderStatusText = (status) => {
    const statuses = {
        received: 'ontvangen',
        partlypayed: 'gedeeltelijk betaald',
        payed: 'betaald',
        send: "verzonden",
        finished: "afgerond",
        error: 'fout'
    }

    return statuses[status] ?? `status onbekend (${status})`
}

export const pickFromObjectToArray = (obj, keys) =>
    keys
        //.filter(key => key in obj)
        .map(key => obj[key])
;
export const pickFromObjectToObject = (obj, keys) => Object.fromEntries(
    keys
        .filter(key => key in obj)
        .map(key => [key, obj[key]])
);

export const merge = (a, b, predicate = (a, b) => a === b) => {
    const c = [...a]; // copy to avoid side effects
    // add all items from B to copy C if they're not already present
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
    return c;
}

export const getChoirColorTypography = (choirs = [], variant = "body") => {
    return choirs.map((choir, i) => (
        <Typography
            variant={variant}
            key={i}
            color={choir.title.replace(/\s+/g, '').toLowerCase() + '.main'}
        >{choir.title}</Typography>
    ))
}

export function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export function useLocationQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}