import React, {createContext, useEffect, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_ONE_CARTITEM_MUTATION, UPSERT_ONE_CARTITEM_MUTATION} from "../utilities/mutation";
import {errorHandler} from "../utilities/funcs";
import {useSnackbar} from "notistack";
import {APPCONTEXT_QUERY, CARTITEMS_QUERY, ORDERITEM_QUERY, PROFILES_QUERY} from "../utilities/query";


export const AppContext = createContext({
    language: 'nl',
    setLanguage: () => {
    },
    findComprop: () => {
    },
    i18nText: ()=>{
    },
    profiles: [],
    permissions: [],
});


export const AppProvider = props => {

    const {enqueueSnackbar} = useSnackbar();
    const [language, setLanguage] = useState('nl')
    const [loading, setLoading] = useState(true)
    const [profiles, setProfiles] = useState([])
    const [permissions, setPermissions] = useState([])

    const {loading: queryLoading} = useQuery(APPCONTEXT_QUERY, {
        notifyOnNetworkStatusChange: true,
        onCompleted: d => {
            setProfiles(d.profiles)
            setPermissions(d.permissions)
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
    });

    const findComprop = (comprops, title, def = "") => {
        const comprop = comprops.find(c => c.title === title)



        if (comprop) {
            if (comprop.type === 'int') return comprop.valueInt
            if (comprop.type === 'string') return comprop.valueString
            return comprop.valueLongtext[language]
        }
        return def
    }

    const i18nText = (item) => {
        return item[language] ?? ''
    }


    return (
        <AppContext.Provider value={{
            language: language,
            setLanguage: setLanguage,
            loading: loading,//testCartMutationLoading || queryLoading || deleteCartitemMutationLoading,
            findComprop: findComprop,
            i18nText: i18nText,
            profiles: profiles,
            permissions: permissions
        }}>
            {props.children}
        </AppContext.Provider>
    )

}
