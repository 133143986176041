import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import DecorationRoot from "./DecorationRoot";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./generalroutes/ErrorPage";


const router = createBrowserRouter([
    {
        path: "/",
        element: <DecorationRoot/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                lazy: () => import("./admin/AdminIndex")
            },
            {
                path: "successlogin",
                lazy: () => import("./admin/AdminIndex")
            },
            {
                path: "appointments",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/appointments/AdminAppointmentIndex")
                    },
                    {
                        path: "add",
                        lazy: () => import("./admin/appointments/AdminAppointmentAdd")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./admin/appointments/AdminAppointmentView")
                    }
                ]
            },
            {
                path: "banners",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/banners/AdminBannerIndex")
                    }
                ]
            },
            {
                path: "products",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/products/AdminProductIndex")
                    },
                    {
                        path: "add",
                        lazy: () => import("./admin/products/AdminProductAdd")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./admin/products/AdminProductView")
                    }
                ]
            },
            {
                path: "pages",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/pages/AdminPageIndex")
                    },
                    {
                        path: "add",
                        lazy: () => import("./admin/pages/AdminPageAdd")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./admin/pages/AdminPageView")
                    }
                ]
            },
            {
                path: "orders",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/orders/AdminOrderIndex")
                    },
                    {
                        path: "add",
                        lazy: () => import("./admin/orders/AdminOrderAdd")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./admin/orders/AdminOrderView")
                    }
                ]
            },
            {
                path: "newsletters",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/newsletters/AdminNewsletterIndex")
                    },
                    {
                        path: "add",
                        lazy: () => import("./admin/newsletters/AdminNewsletterAdd")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./admin/newsletters/AdminNewsletterView")
                    }
                ]
            },
            {
                path: "newsitems",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/newsitems/AdminNewsitemIndex")
                    },
                    {
                        path: "add",
                        lazy: () => import("./admin/newsitems/AdminNewsitemAdd")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./admin/newsitems/AdminNewsitemView")
                    }
                ]
            },
            {
                path: "emailpresets",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/emailpresets/AdminEmailPresetIndex")
                    },
                    {
                        path: "add",
                        lazy: () => import("./admin/emailpresets/AdminEmailPresetAdd")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./admin/emailpresets/AdminEmailPresetView")
                    }
                ]
            },
            {
                path: "receivers",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/receivers/AdminReceiverIndex")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./admin/receivers/AdminReceiverView")
                    }
                ]
            },
            {
                path: "users",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/users/AdminUserIndex")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./admin/users/AdminUserView")
                    }
                ]
            },
            {
                path: "profiles",
                children: [
                    {
                        index: true,
                        lazy: () => import("./admin/profiles/AdminProfileIndex")
                    },
                    {
                        path: ":id",
                        lazy: () => import("./admin/profiles/AdminProfileView")
                    }
                ]
            }
        ]
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
