import {Box, Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {Form, Formik} from "formik";
import {useState} from "react";

const FormCreate = (
    {
        presets = [],
        enableReinitialize = false,
        sendText = 'Verzenden',
        initialValues, loading, submitFunc, validationSchema,
        children
    }
) => {

    const [iniValues, setIniValues] = useState(initialValues)
    const [preset, setPreset] = useState('')

    const handlePresetChange = (e) => {
        setIniValues(presets[e.target.value].values)
        setPreset(e.target.value);
    }
    const getPresets = () => {
        if (presets.length > 0) {
            return (
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={preset}
                        onChange={handlePresetChange}
                        displayEmpty
                    >
                        <MenuItem disabled value="">
                            <em>Kies een preset</em>
                        </MenuItem>
                        {presets.map((preset, index) => (
                            <MenuItem key={index} value={index}>{preset.title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
        } else {
            return null
        }
    }


    return (
        <Box sx={{mb: 3}}>
            <Formik
                onSubmit={async (values) => {
                    // alert(JSON.stringify(values, null, 2));
                    submitFunc(values)
                }}

                initialValues={iniValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                initialStatus={{
                    readOnly: false
                }}
                enableReinitialize={enableReinitialize}
            >
                {(fProps) => (
                    <Form>
                        {getPresets()}
                        {children}
                        {!Boolean(fProps?.status?.readOnly) && <Button type="submit" variant={"outlined"}
                                                                       disabled={!fProps.isValid || fProps.isSubmitting || loading}>{sendText}</Button>}
                    </Form>

                )}
            </Formik>
        </Box>
    )
}

export default FormCreate

