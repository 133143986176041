import {gql} from "@apollo/client";
import {
    APPOINTMENT_FRAGMENT,
    COMPONENT_FRAGMENT,
    COMPROPS_FRAGMENT,
    FILE_FRAGMENT,
    INVOICE_FRAGMENT,
    NEWSITEM_FRAGMENT,
    NEWSLETTER_FRAGMENT,
    NEWSLETTERITEM_FRAGMENT,
    ORDER_FRAGMENT,
    ORDERITEM_FRAGMENT,
    PAGE_FRAGMENT,
    PRODUCT_FRAGMENT,
    SUBPRODUCT_FRAGMENT,
    USER_FRAGMENT
} from "./fragments";

export const USER_LOGIN_MUTATION = gql`
    ${USER_FRAGMENT}
    mutation UserLogin($username: String!, $password: String!) {
        userLogin(username: $username, password: $password) {
            ...UserFragment
        }
    }
`;

export const USER_LOGOUT_MUTATION = gql`
    mutation UserLogout{
        userLogout{
            success
        }
    }
`;
export const USER_LOGIN_LIKE_MUTATION = gql`
    ${USER_FRAGMENT}
    mutation UserLoginLike($id: String!) {
        userLoginLike(id: $id){
            user {
                ...UserFragment
            }
        }
    }
`;
export const FILE_DELETE_MUTATION = gql`
    mutation DeleteFile($id: String!) {
        deleteFile(id: $id)
    }
`

export const SEND_EMAIL_MUTATION = gql`
    mutation SendEmail($to: String!, $from: String!, $subject: String!, $text: String!){
        sendEmail(to: $to, from: $from, subject: $subject, text: $text){
            success
        }
    }
`;
export const COMPONENT_CREATE_MUTATION = gql`
    mutation CreateOneComponent($data: ComponentCreateInput!, $files: [Upload!]){
        createOneComponent(data: $data, files: $files){
            id
        }
    }
`;

export const COMPONENT_UPDATE_MUTATION = gql`
    ${COMPONENT_FRAGMENT}
    mutation UpdateOneComponent($data: ComponentUpdateInput!, $where: ComponentWhereUniqueInput!){
        updateOneComponent(data: $data, where: $where){
            ...ComponentFragment
        }
    }
`;

export const COMPONENT_RERANG_MUTATION = gql`
    ${COMPONENT_FRAGMENT}
    mutation RerangOneComponent($direction: Boolean!, $where: ComponentWhereUniqueInput!){
        rerangOneComponent(direction: $direction, where: $where){
            ...ComponentFragment
        }
    }
`;

export const COMPONENT_DELETE_MUTATION = gql`
    mutation DeleteOneComponent($where: ComponentWhereUniqueInput!){
        deleteOneComponent(where: $where){
            id
        }
    }
`;

export const COMPROP_UPDATE_MUTATION = gql`
    ${COMPROPS_FRAGMENT}
    mutation UpdateOneComprops($data: CompropsUpdateInput!, $where: CompropsWhereUniqueInput!){
        updateOneComprops(data: $data, where: $where){
            ...CompropsFragment
        }
    }
`;

export const PAGE_CREATE_MUTATION = gql`
    mutation CreateOnePage($data: PageCreateInput!){
        createOnePage(data: $data){
            id
        }
    }
`;

export const PAGE_UPDATE_MUTATION = gql`
    ${PAGE_FRAGMENT}
    mutation UpdateOnePage($data: PageUpdateInput!, $where: PageWhereUniqueInput!){
        updateOnePage(data: $data, where: $where){
            ...PageFragment
        }
    }
`;

export const PAGE_DELETE_MUTATION = gql`
    mutation DeleteOnePage($where: PageWhereUniqueInput!){
        deleteOnePage(where: $where){
            id
        }
    }
`;


export const APPOINTMENT_CREATE_MUTATION = gql`
    mutation CreateOneAppointment($data: AppointmentCreateInput!){
        createOneAppointment(data: $data){
            id
        }
    }
`;

export const APPOINTMENT_UPDATE_MUTATION = gql`
    ${APPOINTMENT_FRAGMENT}
    mutation UpdateOneAppointment($data: AppointmentUpdateInput!, $where: AppointmentWhereUniqueInput!){
        updateOneAppointment(data: $data, where: $where){
            ...AppointmentFragment
        }
    }
`;

export const APPOINTMENT_DELETE_MUTATION = gql`
    mutation DeleteOneAppointment($where: AppointmentWhereUniqueInput!){
        deleteOneAppointment(where: $where){
            id
        }
    }
`;

export const APPOINTMENT_TOGGLE_CALENDAR_MUTATION = gql`
    ${APPOINTMENT_FRAGMENT}
    mutation ToggleCalendarAppointment($where: AppointmentWhereUniqueInput!){
        toggleCalendar(where: $where){
            ...AppointmentFragment
        }
    }
`;


export const MUSICWORK_CREATE_MUTATION = gql`
    mutation CreateOneMusicwork($data: MusicworkCreateInput!){
        createOneMusicwork(data: $data){
            id
        }
    }
`;

export const MUSICWORK_UPDATE_MUTATION = gql`
    mutation UpdateOneMusicwork($data: MusicworkUpdateInput!, $where: MusicworkWhereUniqueInput!){
        updateOneMusicwork(data: $data, where: $where){
            id
            title
            composer
        }
    }
`;

export const MUSICWORK_DELETE_MUTATION = gql`
    mutation DeleteOneMusicwork($where: MusicworkWhereUniqueInput!){
        deleteOneMusicwork(where: $where){
            id
        }
    }
`;


export const EXECUTIVE_CREATE_MUTATION = gql`
    mutation CreateOneExecutive($data: ExecutiveCreateInput!){
        createOneExecutive(data: $data){
            id
        }
    }
`;

export const EXECUTIVE_UPDATE_MUTATION = gql`
    mutation UpdateOneExecutive($data: ExecutiveUpdateInput!, $where: ExecutiveWhereUniqueInput!){
        updateOneExecutive(data: $data, where: $where){
            id
            title
            function
        }
    }
`;

export const EXECUTIVE_DELETE_MUTATION = gql`
    mutation DeleteOneExecutive($where: ExecutiveWhereUniqueInput!){
        deleteOneExecutive(where: $where){
            id
        }
    }
`;


export const PRODUCT_CREATE_MUTATION = gql`
    mutation CreateOneProduct($data: ProductCreateInput!){
        createOneProduct(data: $data){
            id
        }
    }
`;

export const PRODUCT_UPDATE_MUTATION = gql`
    ${PRODUCT_FRAGMENT}
    mutation UpdateOneProduct($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!){
        updateOneProduct(data: $data, where: $where){
            ...ProductFragment
        }
    }
`;

export const PRODUCT_DELETE_MUTATION = gql`
    mutation DeleteOneProduct($where: ProductWhereUniqueInput!){
        deleteOneProduct(where: $where){
            id
        }
    }
`;


export const SUBPRODUCT_CREATE_MUTATION = gql`
    mutation CreateOneSubproduct($data: SubproductCreateInput!){
        createOneSubproduct(data: $data){
            id
        }
    }
`;

export const SUBPRODUCT_UPDATE_MUTATION = gql`
    ${SUBPRODUCT_FRAGMENT}
    mutation UpdateOneSubproduct($data: SubproductUpdateInput!, $where: SubproductWhereUniqueInput!){
        updateOneSubproduct(data: $data, where: $where){
            ...SubproductFragment
        }
    }
`;

export const SUBPRODUCT_DELETE_MUTATION = gql`
    mutation DeleteOneSubproduct($where: SubproductWhereUniqueInput!){
        deleteOneSubproduct(where: $where){
            id
        }
    }
`;


export const ORDER_CREATE_MUTATION = gql`
    mutation CreateOneOrder($data: OrderCreateInput!){
        createOneOrder(data: $data){
            id
        }
    }
`;

export const ORDER_CREATE_FROM_CART_MUTATION = gql`
    mutation CreateOrderFromCart($data: OrderCreateInput!){
        createOrderFromCart(data: $data){
            id
        }
    }
`;

export const ORDER_UPDATE_MUTATION = gql`
    ${ORDER_FRAGMENT}
    mutation UpdateOneOrder($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!){
        updateOneOrder(data: $data, where: $where){
            ...OrderFragment
        }
    }
`;

export const ORDER_DELETE_MUTATION = gql`
    mutation DeleteOneOrder($where: OrderWhereUniqueInput!){
        deleteOneOrder(where: $where){
            id
        }
    }
`;


export const ORDERITEM_CREATE_MUTATION = gql`
    mutation CreateOneOrderitem($invoiceId: Int!, $price: Float!, $amount: Int!, $subproductId: Int!){
        createOneOrderitem(invoiceId: $invoiceId, price: $price, amount: $amount, subproductId: $subproductId){
            id
        }
    }
`;

export const CREDIT_ORDERITEM_CREATE_MUTATION = gql`
    mutation CreateOneCreditOrderitem($invoiceId: Int!, $price: Float!, $amount: Int!, $orderitemId: Int!){
        createOneCreditOrderitem(invoiceId: $invoiceId, price: $price, amount: $amount, orderitemId: $orderitemId){
            id
        }
    }
`;

export const ORDERITEM_UPDATE_MUTATION = gql`
    ${ORDERITEM_FRAGMENT}
    mutation UpdateOneOrderitem($data: OrderitemUpdateInput!, $where: OrderitemWhereUniqueInput!){
        updateOneOrderitem(data: $data, where: $where){
            ...OrderitemFragment
        }
    }
`;

export const ORDERITEM_DELETE_MUTATION = gql`
    mutation DeleteOneOrderitem($where: OrderitemWhereUniqueInput!){
        deleteOneOrderitem(where: $where){
            id
        }
    }
`;


export const INVOICE_CREATE_MUTATION = gql`
    mutation CreateOneInvoice($data: InvoiceCreateInput!){
        createOneInvoice(data: $data){
            id
        }
    }
`;

export const INVOICE_UPDATE_MUTATION = gql`
    ${INVOICE_FRAGMENT}
    mutation UpdateOneInvoice($data: InvoiceUpdateInput!, $where: InvoiceWhereUniqueInput!){
        updateOneInvoice(data: $data, where: $where){
            ...InvoiceFragment
        }
    }
`;

export const INVOICE_UPSERT_MUTATION = gql`
    ${INVOICE_FRAGMENT}
    mutation UpsertOneInvoice($update: InvoiceUpdateInput!, $create: InvoiceCreateInput!, $where: InvoiceWhereUniqueInput!){
        upsertOneInvoice(update: $update, create: $create, where: $where){
            ...InvoiceFragment
        }
    }
`;

export const INVOICE_DELETE_MUTATION = gql`
    mutation DeleteOneInvoice($where: InvoiceWhereUniqueInput!){
        deleteOneInvoice(where: $where){
            id
        }
    }
`;

export const NEWSLETTER_CREATE_MUTATION = gql`
    mutation CreateOneNewsletter($data: NewsletterCreateInput!){
        createOneNewsletter(data: $data){
            id
        }
    }
`;

export const NEWSLETTER_UPDATE_MUTATION = gql`
    ${NEWSLETTER_FRAGMENT}
    mutation UpdateOneNewsletter($data: NewsletterUpdateInput!, $where: NewsletterWhereUniqueInput!){
        updateOneNewsletter(data: $data, where: $where){
            ...NewsletterFragment
        }
    }
`;

export const NEWSLETTER_DELETE_MUTATION = gql`
    mutation DeleteOneNewsletter($where: NewsletterWhereUniqueInput!){
        deleteOneNewsletter(where: $where){
            id
        }
    }
`;

export const NEWSLETTER_SEND_MUTATION = gql`
    mutation SendNewsletter($where: NewsletterWhereUniqueInput!, $to: String!){
        sendNewsletter(where: $where, to: $to){
            success
            count
            date
        }
    }
`;

export const NEWSLETTERITEM_CREATE_MUTATION = gql`
    mutation CreateOneNewsletteritem($data: NewsletteritemCreateInput!){
        createOneNewsletteritem(data: $data){
            id
        }
    }
`;

export const NEWSLETTERITEM_UPDATE_MUTATION = gql`
    ${NEWSLETTERITEM_FRAGMENT}
    mutation UpdateOneNewsletteritem($data: NewsletteritemUpdateInput!, $where: NewsletteritemWhereUniqueInput!){
        updateOneNewsletteritem(data: $data, where: $where){
            ...NewsletteritemFragment
        }
    }
`;

export const NEWSLETTERITEM_DELETE_MUTATION = gql`
    mutation DeleteOneNewsletteritem($where: NewsletteritemWhereUniqueInput!){
        deleteOneNewsletteritem(where: $where){
            id
        }
    }
`;

export const NEWSLETTERITEM_RERANG_MUTATION = gql`
    ${NEWSLETTERITEM_FRAGMENT}
    mutation ChangeRangNewsletteritem($where: NewsletteritemWhereUniqueInput!, $direction: Boolean!){
        changeRangNewsletteritem(where: $where, direction: $direction){
            ...NewsletteritemFragment
        }
    }
`;

export const RECEIVER_CREATE_MUTATION = gql`
    mutation CreateOneReceiver($data: ReceiverCreateInput!){
        createOneReceiver(data: $data){
            id
            email
        }
    }
`;

export const RECEIVER_UPDATE_MUTATION = gql`
    mutation UpdateOneReceiver($data: ReceiverUpdateInput!, $where: ReceiverWhereUniqueInput!){
        updateOneReceiver(data: $data, where: $where){
            id
            email
        }
    }
`;

export const RECEIVER_DELETE_MUTATION = gql`
    mutation DeleteOneReceiver($where: ReceiverWhereUniqueInput!){
        deleteOneReceiver(where: $where){
            id
            email
        }
    }
`;


export const USER_CREATE_MUTATION = gql`
    mutation CreateOneUser($data: UserCreateInput!){
        createOneUser(data: $data){
            id
        }
    }
`;

export const USER_UPDATE_MUTATION = gql`
    ${USER_FRAGMENT}
    mutation UpdateOneUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!){
        updateOneUser(data: $data, where: $where){
            ...UserFragment
        }
    }
`;
export const USER_NEWPASSWORD_MUTATION = gql`
    mutation NewPasswordUser($email: String!){
        userNewPassword(email: $email){
            success
        }
    }
`;

export const USER_DELETE_MUTATION = gql`
    mutation DeleteOneUser($where: UserWhereUniqueInput!){
        deleteOneUser(where: $where){
            id
        }
    }
`;

export const UPSERT_ONE_CARTITEM_MUTATION = gql`
    mutation UpsertOneCartitem($delta: Int!, $where: SubproductWhereUniqueInput!){
        upsertOneCartitem(delta: $delta, where: $where){
            id
            amount

        }
    }
`;

export const DELETE_ONE_CARTITEM_MUTATION = gql`
    mutation DeleteOneCartitem($where: SubproductWhereUniqueInput!){
        deleteOneCartitem(where: $where){
            id
        }
    }
`;

export const CREATE_PAYMENT_MUTATION = gql`
    mutation CreatePayment($where: OrderWhereUniqueInput!, $bankId: String!){
        createPayment(where: $where, bankId: $bankId)
    }
`;


export const NEWSITEM_CREATE_MUTATION = gql`
    mutation CreateOneNewsitem($data: NewsitemCreateInput!){
        createOneNewsitem(data: $data){
            id
        }
    }
`;

export const NEWSITEM_UPDATE_MUTATION = gql`
    ${NEWSITEM_FRAGMENT}
    mutation UpdateOneitem($data: NewsitemUpdateInput!, $where: NewsitemWhereUniqueInput!){
        updateOneNewsitem(data: $data, where: $where){
            ...NewsitemFragment
        }
    }
`;

export const NEWSITEM_DELETE_MUTATION = gql`
    mutation DeleteOneNewsitem($where: NewsitemWhereUniqueInput!){
        deleteOneNewsitem(where: $where){
            id
        }
    }
`;

export const EMAILPRESET_CREATE_MUTATION = gql`
    mutation CreateOneEmailPreset($data: EmailPresetCreateInput!){
        createOneEmailPreset(data: $data){
            id
        }
    }
`;

export const EMAILPRESET_UPDATE_MUTATION = gql`
    mutation UpdateOneEmailPreset($data: EmailPresetUpdateInput!, $where: EmailPresetWhereUniqueInput!){
        updateOneEmailPreset(data: $data, where: $where){
            id
            title
            subject
            from
            text{
                id
                nl
                en
            }
        }
    }
`;

export const EMAILPRESET_DELETE_MUTATION = gql`
    mutation DeleteOneEmailPreset($where: EmailPresetWhereUniqueInput!){
        deleteOneEmailPreset(where: $where){
            id
        }
    }
`;


export const BANNER_CREATE_MUTATION = gql`
    mutation CreateOneBanner($data: BannerCreateInput!, $files: [Upload!]){
        createOneBanner(data: $data, files: $files){
            id
        }
    }
`;

export const BANNER_UPDATE_MUTATION = gql`
    ${FILE_FRAGMENT}
    mutation UpdateOneBanner($data: BannerUpdateInput!, $where: BannerWhereUniqueInput!){
        updateOneBanner(data: $data, where: $where){
            id
            files{
                ...FileFragment
            }
        }
    }
`;


export const BANNER_DELETE_MUTATION = gql`
    mutation DeleteOneBanner($where: BannerWhereUniqueInput!){
        deleteOneBanner(where: $where){
            id
        }
    }
`;

export const PROFILE_UPDATE_MUTATION = gql`
    mutation UpdateOneProfile($data: ProfileUpdateInput!, $where: ProfileWhereUniqueInput!){
        updateOneProfile(data: $data, where: $where){
            id
            title
            permissions{
                id
                title
            }
        }
    }
`;


export const PROFILE_DELETE_MUTATION = gql`
    mutation DeleteOneProfile($where: ProfileWhereUniqueInput!){
        deleteOneProfile(where: $where){
            id
        }
    }
`;
