import {DateTime} from "luxon";
import {Album, ConfirmationNumber, LocalShipping, VolunteerActivism} from "@mui/icons-material";

export const PORT_SERVER = '4010'
export const BASE_URL_SERVER = 'https://graphql.laurensvocaal.nl' //http://localhost:' + PORT_SERVER
export const BASE_URL_CLIENT = 'https://admin.laurensvocaal.nl' //'http://localhost:3000'


export const i18nOptions = [
    {id: "nl", title: 'nederlands'},
    {id: "en", title: 'engels'},
]

export const choirColors = {
    1: "#ef7132",
    2: "#69b7e8",
    3: "#4ca832"
}
export const PRODUCTTYPESICONS = {
    cd: <Album/>,
    ticket: <ConfirmationNumber/>,
    shipping: <LocalShipping/>,
    donation: <VolunteerActivism/>
}

export const getChoirColor = (choirs = []) => {
    return choirs[0]?.id ? choirColors[choirs[0].id] : "black"
}

export const COMPONENTS = [
    {
        title: "centerText",
        label: "gecentreerde tekst/link",
        files: null,
        options: [
            {
                title: "text",
                label: "tekst",
                type: "longtext",
                dbtype: 'longtext',
                default: ""
            },
        ]
    },
    {
        title: "centerImage",
        label: "gecentreerde foto",
        files: {
            maxFiles: 1,
        },
        options: [
            {
                title: "imageProps",
                label: "type",
                type: "radio",
                dbtype: "string",
                options: [
                    {title: "beeldbreed", id: 'fluid'},
                    {title: "gecentreerd", id: 'centered'},
                ],
                default: "centered",
                parse: 'json'
            },
            {
                title: "width",
                label: "breedte (optioneel, alleen bij gecentreerd)",
                type: "text",
                dbtype: "string",
                default: ""
            },
            {
                title: "url",
                label: "linkadres (optioneel)",
                type: "text",
                dbtype: 'string',
                default: ""
            },
        ]
    },
    {
        title: "photoText",
        label: "foto('s) met tekst",
        files: {
            maxFiles: 3,
        },
        options: [
            {
                title: "placement",
                label: "zijdigheid tekst",
                type: "radio",
                dbtype: 'string',
                options: [
                    {title: "links", id: 'left'},
                    {title: "rechts", id: 'right'},
                ],
                default: "right"
            },
            {
                title: "title",
                label: "titel",
                type: "i18ntext",
                dbtype: 'longtext',
                default: ""
            },
            {
                title: "text",
                label: "tekst",
                type: "longtext",
                dbtype: 'longtext',
                default: ""
            }
        ]
    },
    {
        title: "listen",
        label: "luistervoorbeelden",
        files: null,
        options: [
            {
                title: "title",
                label: "titel (optioneel)",
                type: "text",
                dbtype: 'string',
                default: ""
            },
            {
                title: "media1",
                label: "youtube id 1",
                type: "text",
                dbtype: 'string',
                default: ""
            },
            {
                title: "media2",
                label: "youtube id 2",
                type: "text",
                dbtype: 'string',
                default: ""
            },
            {
                title: "media3",
                label: "youtube id 3",
                type: "text",
                dbtype: 'string',
                default: ""
            }
        ]
    },
    {
        title: "listenText",
        label: "luistervoorbeeld met tekst",
        files: null,
        options: [
            {
                title: "media1",
                label: "youtube id 1",
                type: "text",
                dbtype: 'string',
                default: ""
            },
            {
                title: "media2",
                label: "youtube id 2",
                type: "text",
                dbtype: 'string',
                default: ""
            },
            {
                title: "media3",
                label: "youtube id 3",
                type: "text",
                dbtype: 'string',
                default: ""
            },
            {
                title: "media4",
                label: "youtube id 4",
                type: "text",
                dbtype: 'string',
                default: ""
            },
            {
                title: "placement",
                label: "zijdigheid tekst",
                type: "radio",
                dbtype: 'string',
                options: [
                    {title: "links", id: 'left'},
                    {title: "rechts", id: 'right'},
                ],
                default: "left"
            },
            {
                title: "title",
                label: "titel",
                type: "i18ntext",
                dbtype: 'longtext',
                default: ""
            },
            {
                title: "text",
                label: "tekst",
                dbtype: 'longtext',
                type: "longtext",
                default: ""
            }
        ]
    },
    {
        title: "threeAppointment",
        label: "agendablok (max 3 items)",
        files: null,
        options: []
    },
    {
        title: "threeNewsitem",
        label: "nieuwsblok (max 3 items)",
        files: null,
        options: []
    },
    {
        title: "spacer",
        label: "afstandhouder (met/zonder lijn)",
        files: null,
        options: [
            {
                title: "size",
                label: "hoogte",
                dbtype: 'string',
                type: "radio",
                options: [
                    {title: "80 hoog", id: 'large'},
                    {title: "40 hoog", id: 'small'}
                ],
                default: 'small'
            },
            {
                title: "divider",
                label: "horizontale lijn",
                type: "radio",
                dbtype: 'string',
                options: [
                    {title: "ja", id: 'true'},
                    {title: "nee", id: 'false'},
                ],
                default: "true"
            },
        ]
    },
]
export const componentPropsNew = (type) => {
    return COMPONENTS.find(t => t.title === type)
}

export const getLogo = (id, title = false, small = true) => {
    const path = `/logo/${small ? 'small' : 'large'}/`
    if (title) {
        switch (id) {
            case 1:
                return path + 'LOGO_LC_INNER_TEXT.png'
            case 2:
                return path + 'LOGO_LCR_INNER_TEXT.png'
            case 3:
                return path + 'LOGO_LS_INNER_TEXT.png'
            default:
                return path + 'LOGO_LV_INNER_TEXT.png'
        }
    } else {
        switch (id) {
            case 1:
                return path + 'LOGO_LC_NO_TEXT.png'
            case 2:
                return path + 'LOGO_LCR_NO_TEXT.png'
            case 3:
                return path + 'LOGO_LS_NO_TEXT.png'
            default:
                return path + 'LOGO_LV_NO_TEXT.png'
        }
    }
}


export const banks = [
    {
        title: "Rabobank",
        id: "ideal/RABONL2U"
    },
    {
        title: "ING",
        id: "ideal/INGBNL2A"
    },
    {
        title: "ABN AMRO",
        id: "ideal/ABNANL2A"
    },
    {
        title: "SNS Bank",
        id: "ideal/SNSBNL2A"
    },
    {
        title: "RegioBank",
        id: "ideal/RBRBNL21"
    },
    {
        title: "ASN Bank",
        id: "ideal/ASNBNL21"
    },
    {
        title: "Knab",
        id: "ideal/KNABNL2H"
    },
    {
        title: "Triodos Bank",
        id: "ideal/TRIONL2U"
    },
    {
        title: "van Lanschot",
        id: "ideal/FVLBNL22"
    },
    {
        title: "Bunq",
        id: "ideal/BUNQNL2A"
    }
]


export const Classes = {
    dropzone: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: "2px",
        borderRadius: "2px",
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    }
}


export const AppointmentPresets = [
    {
        title: "Blanco",
        values: {
            title: '',
            start: DateTime.local(),
            end: DateTime.local().plus({hour: 1}),
            showFrom: DateTime.local(),
            showTo: DateTime.local().plus({day: 1}),
            description: {en: '', nl: ''},
            location: '',
            access: true,
            profiles: [],
            choirs: [],
            entrancetype: 'free'
        }
    },
    {
        title: "Ochtenddienst A",
        values: {
            title: 'Ochtenddienst groep A',
            start: DateTime.fromObject({weekday: 7, hour: 10, minute: 30, second: 0, millisecond: 0}),
            end: DateTime.fromObject({weekday: 7, hour: 12, minute: 0, second: 0, millisecond: 0}).plus({hour: 1}),
            showFrom: null,
            showTo: DateTime.fromObject({weekday: 7, hour: 12, minute: 0, second: 0, millisecond: 0}).plus({
                hour: 1,
                day: 1
            }),
            description: {en: '', nl: ''},
            location: 'Laurenskerk Rotterdam',
            access: false,
            profiles: [3],
            choirs: [1],
            entrancetype: 'free'
        }
    },
    {
        title: "Ochtenddienst B",
        values: {
            title: 'Ochtenddienst groep B',
            start: DateTime.fromObject({weekday: 7, hour: 10, minute: 30, second: 0, millisecond: 0}),
            end: DateTime.fromObject({weekday: 7, hour: 12, minute: 0, second: 0, millisecond: 0}),
            showFrom: null,
            showTo: DateTime.fromObject({weekday: 7, hour: 12, minute: 0, second: 0, millisecond: 0}).plus({'day': 1}),
            description: {en: '', nl: ''},
            location: 'Laurenskerk Rotterdam',
            access: false,
            profiles: [3],
            choirs: [1],
            entrancetype: 'free'
        }
    },
    {
        title: "Cantatedienst",
        values: {
            title: 'Cantatedienst',
            start: DateTime.fromObject({weekday: 7, hour: 19, minute: 0, second: 0, millisecond: 0}),
            end: DateTime.fromObject({weekday: 7, hour: 20, minute: 15, second: 0, millisecond: 0}),
            showFrom: null,
            showTo: DateTime.fromObject({weekday: 7, hour: 20, minute: 15, second: 0, millisecond: 0}).plus({'day': 1}),
            description: {en: 'Cantata service', nl: 'Cantatedienst'},
            location: 'Laurenskerk Rotterdam',
            access: true,
            profiles: [],
            choirs: [1],
            entrancetype: 'collecte'
        }
    }
]

export const ENTRANCETYPE_OPTIONS = [
    {id: 'free', title: 'toegang vrij'},
    {id: 'collecte', title: 'toegang vrij, collecte bij de uitgang'},
    {id: 'own', title: 'in eigen beheer'},
    {id: 'external', title: 'externe kaartverkoop, zie toelichting'}
]

export const TICKET_PRESETS = [
    {
        title: "sprinter",
        values: {
            title: 'sprinter',
            price: 10.00,
            tax: 19,
            stock: 9999,
            access: false,
            profiles: [1],
        }
    },
    {
        title: "reguliere ticket €10",
        values: {
            title: 'reguliere ticket',
            price: 10.00,
            tax: 19,
            stock: 9999,
            access: true,
            profiles: [],
        }
    },
    {
        title: "reguliere ticket €25",
        values: {
            title: 'reguliere ticket',
            price: 25.00,
            tax: 19,
            stock: 9999,
            access: true,
            profiles: [],
        }
    },
    {
        title: "luxe ticket €40",
        values: {
            title: 'luxe ticket',
            price: 40.00,
            tax: 19,
            stock: 9999,
            access: true,
            profiles: [],
        }
    },
]


export const CHOIR_LOGO = {
    CAN: <img src="/logo/small/LOGO_LC_NO_TEXT.png"/>,
    COL: <img src="/logo/small/LOGO_LCR_NO_TEXT.png"/>,
    SYM: <img src="/logo/small/LOGO_LS_NO_TEXT.png"/>,
}