import {gql} from '@apollo/client';

export const FILE_FRAGMENT = gql`
    fragment FileFragment on File {
        id
        mimetype
        model
        modelId
        path
        filename
    }
`

export const COMPONENT_FRAGMENT = gql`
    fragment ComponentFragment on Component {
        id
        type
        rang
        comprops{
            id
            title
            type
            valueInt
            valueString
            valueLongtext{
                id
                nl
                en
            }
        }
    }
`;
export const COMPROPS_FRAGMENT = gql`
    fragment CompropsFragment on Comprops {
        id
        title
        type
        valueInt
        valueString
        valueLongtext{
            id
            nl
            en
        }
    }
`;
export const PAGE_FRAGMENT = gql`
    ${COMPONENT_FRAGMENT}
    fragment PageFragment on Page {
        id
        title
        name
        components(orderBy: [{rang: asc}]){
            ...ComponentFragment
            files{
                id
                path
                mimetype
            }
        }
    }
`;


export const ORDER_FRAGMENT = gql`
    fragment OrderFragment on Order {
        id
        created
        user{
            id
            prename
            insertion
            surname
        }
        remarks{
            id
            nl
            en
        }
        archive
        active

        company
        salutation
        prename
        insertion
        surname
        address
        postal
        city
        country
        email
        invoices(where: {creditToInvoiceId: null}){
            id
            created
            status
        }
    }
`;

export const ORDERITEM_FRAGMENT = gql`
    fragment OrderitemFragment on Orderitem {
        id
        type
        title
        amount
        payed
        send
        subproduct{
            id
            title
            product{
                id
                title
            }
        }
    }
`;

export const INVOICE_FRAGMENT = gql`
    fragment InvoiceFragment on Invoice {
        id
        created
        price
        payed
        status
        invoiceitems{
            id
            title
            amount
            price
            tax
        }
        creditToInvoice{
            id
        }
    }
`;


export const PRODUCT_FRAGMENT = gql`
    fragment ProductFragment on Product {
        id
        title
        description{
            id
            nl
            en
        }
        showFrom
        showTo
        access
        active
        stock
        sold
        type
        created
        appointments{
            id
            title
        }
    }
`;

export const SUBPRODUCT_FRAGMENT = gql`
    fragment SubproductFragment on Subproduct {
        id
        title
        price
        min
        max
        stock
        access
        tax
        active
        profiles{
            id
        }
    }
`;

export const APPOINTMENT_FRAGMENT = gql`
    fragment AppointmentFragment on Appointment {
        id
        title
        start
        end
        showFrom
        showTo
        location
        modified
        description{
            id
            nl
            en
        }
        gcal
        entrancetype
        entranceexternal{
            id
            nl
            en
        }
        product {
            id
            title
            stock
            subproducts{
                id
                title
                price
                min
                max
                stock
            }
        }
        access
        profiles{
            id
            title
        }
        files{
            id
            mimetype
            model
            modelId
            path
            filename
        }
        musicworks{
            id
            title
            composer
        }
        executives{
            id
            title
            function
        }
        choirs{
            id
            title
        }
    }
`;


export const NEWSITEM_FRAGMENT = gql`
    fragment NewsitemFragment on Newsitem {
        id
        created
        modified
        title{
            id
            nl
            en
        }
        text{
            id
            nl
            en
        }
        files{
            id
            mimetype
            model
            modelId
            path
            filename
        }
    }
`;

export const NEWSLETTER_FRAGMENT = gql`
    ${FILE_FRAGMENT}
    fragment NewsletterFragment on Newsletter {
        id
        title
        created
        modified
        send
        calendar
        toMailinglist
        banner{
            id
            files{
                ...FileFragment
            }
        }
        text
        profiles{
            id
            title
        }
    }
`;

export const NEWSLETTERITEM_FRAGMENT = gql`
    ${FILE_FRAGMENT}
    fragment NewsletteritemFragment on Newsletteritem {
        id
        logo
        text
        rang
        files{
            ...FileFragment
        }
    }
`;

export const USER_FRAGMENT = gql`
    fragment UserFragment on User {
        id
        prefix
        prename
        insertion
        surname
        email
        active
        profiles{
            id
            title
        }
    }
`;